import React, { Component } from 'react';
import { Route,  withRouter } from 'react-router-dom';
import {Security,  SecureRoute, LoginCallback } from '@okta/okta-react';
import oktaAuth, {restoreOriginalUri} from "./app.config"
import ErrorPage from '../error-boundary/ErrorPage';
import { ThemeProvider } from '@material-ui/core/styles';
import MaterialTheme from './MaterialTheme';
import Home from './home';

export default 
  withRouter(  class App extends Component {
  constructor(props) {
    super(props);     
    this.state = {
      isLoggedOut : false,
      errorId : 0,
      errorDesc: ''
    }
  }

  componentDidMount() {
    console.log("Loading App");
  }
  render() {
    return (
      this.state.isLoggedOut ? <ErrorPage isLoggedIn = {!this.state.isLoggedOut} errorId = {this.state.errorId} errorDesc = {this.state.errorDesc} ></ErrorPage>:
      <>
      <ThemeProvider theme={MaterialTheme}>
          <Security oktaAuth = {oktaAuth} restoreOriginalUri={restoreOriginalUri}>
               {/* <SecureRoute true path='/caster-tv-jwp'  component={TVCastingHome}/>
               <SecureRoute true path='/caster-feature'  component={FeatureCastingHome}/>
               <SecureRoute true path='/caster-animation'  component={AnimationHome}/> */}
               <SecureRoute true  path='/'  component={Home}/>
               <Route path='/callback' component={LoginCallback}/>
          </Security>
      </ThemeProvider>
     </>
    );
  }
}
); 