import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBNavbarLink, MDBDropdownLink
} from 'mdb-react-ui-kit';
import { BrowserRouter as Router, Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'
import logo from '../assets/WB_logo.png';
import { withOktaAuth } from '@okta/okta-react';
import AsynchronousSearch from '../components/SharedComponents/AsynchronousSerach/AsynchronousSearch'
import { withUserContext } from '../../contexts/UserContext';
import { withNotificationContext } from '../../contexts/NotificationContext';
import MessageModal from '../components/SharedComponents/MessageModal';
import NavMenu from '../pages/NavigationMenu/NavMenu';
import UserManual from '../assets/Animation Caster__User Manual.pdf';

export default 
    withUserContext(
        withNotificationContext(
        withOktaAuth(class HeaderContent extends React.Component {
            constructor(props) {
                super(props);
                this.logout = this.logout.bind(this);
                this.login = this.login.bind(this);
                this.state = {
                    confirmSaveChanges: false,
                    switch_tenant_id: 0,
                    switch_tenant_name: "",
                    isManageUser: false
                }
            }

            async logout() {
                var len = localStorage.length;
                var keys = Object.keys(localStorage);
                for (var i = 0; i < len; i++) {
                    var key = keys[i];
                    if (key.indexOf("okta-") == -1)
                        localStorage.removeItem(key);
                }

                this.props.oktaAuth.signOut('/');
            }


            async login() {
                // Redirect to '/' after logout
                this.props.authService.login('/');
            }
            handleSideNavActive = (value, routePath) => {
                this.props.history.push(routePath, this.state);
            }

            switchTenant = (tenant_id, tenant_name) => {
                this.setState({ switch_tenant_id: tenant_id, switch_tenant_name: tenant_name, confirmSaveChanges: !this.state.confirmSaveChanges });
            }

            confirmCancelChanges = (isCancel) => {
                //if (isCancel) {
                this.props.userContext.switchTenant(this.state.switch_tenant_id);
                //}
                //else {
                this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                //}
            }
            componentWillReceiveProps(nextProps) {
                this.setState({
                    isManageUser: nextProps.userContext?.active_tenant?.permissions?.isManageUser
                });
            }

            render() {
                const bgColorBlue = { backgroundColor: '#0062AF' }
                const adminTitle = { fontSize: '13px', color: '#ffffff', padding: '2px' }
                const ptli = { paddingTop: '0.1rem' }

                return (
                    <div id="headerContent">
                        {/* <Router fixed="top"> */}
                        <header  >
                            <div className="fixed-top">
                                <MDBNavbar bgColor="primary" dark expand="md" fixed sticky >
                                    <MDBContainer fluid>
                                        <MDBNavbarBrand className='mb-0 h1'>

                                            {/* <MDBNavbarLink tag={Link} active={false} className="nav-link-override" to="/" > */}
                                            <Link component={RouterLink} className="action-icon" to={'/caster-animation'} >
                                                <img
                                                    src={logo}
                                                    alt='Logo' className="animationImageWidth"
                                                //onClick={this.handleSideNavActive.bind(this, 'LP', "/")}
                                                />
                                                </Link>

                                            {/* </MDBNavbarLink> */}
                                            <h1 className="app-title">
                                                CASTER 
                                        </h1>
                                        </MDBNavbarBrand>
                                        <NavMenu history={this.props?.history}/>
                                        <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>
                                            <MDBNavbarItem className="padding-hor-25" >
                                                <MDBDropdown >
                                                    <MDBDropdownToggle tag="a" className="bgColorBlue shadow-none text-white" >
                                                        <MDBIcon fas size='3x' icon="user-circle" className="mr-2" />
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu className="text-end border border-1-quinary">
                                                        <MDBDropdownItem data-active={false} key={"manual"}>
                                                            <MDBDropdownLink className="text-tertiary logout-link" href={UserManual} download="Animation Caster__User Manual.pdf">
                                                                <small>{'User Manual'}</small>
                                                            </MDBDropdownLink>
                                                        </MDBDropdownItem>
                                                        <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}
                                                            disabled={!this.props.isLoggedIn} onClick={this.logout} >
                                                            <MDBDropdownLink className="fw-bold" active={false} to='/'>Logout</MDBDropdownLink >
                                                        </MDBDropdownItem>
                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </MDBNavbarItem>
                                        </MDBNavbarNav>
                                    </MDBContainer>
                                </MDBNavbar>
                                <MessageModal
                                    open={this.state.confirmSaveChanges}
                                    title={"Unsaved Changes"}
                                    message={"Switching tenant will lose any unsaved changes. Do you want to proceed?"}
                                    primaryButtonText={"Yes"}
                                    secondaryButtonText={"No"}
                                    onConfirm={() => this.confirmCancelChanges()}
                                    handleClose={(e) => this.setState({ confirmSaveChanges: false })} />
                            </div>
                        </header>
                    </div>
                );
            }
        }
        )
    ));