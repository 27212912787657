import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {
  BrowserRouter as Router, withRouter
} from "react-router-dom";
import TVCastingHome from '../caster-tv-jwp/home/Home';
import FeatureCastingHome from '../caster-feature/home/Home';
import AnimationCastingHome from '../caster-animation/home/Home';

export default withRouter(
  withOktaAuth(class Home extends Component {

    constructor(props) {
      super(props);
      if (this.props.authState.accessToken == undefined)
        this.props.oktaAuth.signOut('/');
      this.idleTimer = null
      this.onIdle = this._onIdle.bind(this);
      this.state = {
        pathName: window.location?.pathname
      }
    }

    _onIdle(e) {

      //authService.logout();
      this.props.oktaAuth.signOut('/');
      this.setState({
        isLoggedOut: true,
        errorId: '440',
        errorDesc: 'Session is timed out. Please login again!'
      });

    }

    async componentDidMount() {
      try {
        this._isMounted = true;
        await this.props.oktaAuth.getUser()
          .then(response => {
            localStorage.setItem('user_full_name', response.name);
            this.setState({ user_full_name: response.name });
          },
            (err) => {
              console.log(err);
            });
      }
      catch (err) { }
    }


    componentDidUpdate(prevProps, prevState) {
      if (this.state.pathName !== window?.location?.pathname) {
        this.setState({ pathName: window?.location?.pathname })
      }
    }
    render() {
      return (
        <Router>
          {
            this.state.pathName?.includes('/caster-tv-jwp') ?
              <TVCastingHome {...this.state} {...this.props} /> :
              this.state.pathName?.includes('/caster-feature') ?
                <FeatureCastingHome {...this.state} {...this.props} /> :
                this.state.pathName?.includes('/caster-animation') ?
                  <AnimationCastingHome {...this.state} {...this.props} /> : <> </>
          }
        </Router>

      );
    }
  }
  )
);