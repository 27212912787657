import React from 'react';
class SearchResultsLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: props.searchResult ? props.searchResult : null
        }
    }
    componentDidMount() {
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.searchResult !== prevState.searchResult) {
            return { searchResult: nextProps.searchResult }
        }
        return null
    }

    render() {
        return (
            <>
                <div class='row'>
                    <div class='col-12'>
                        {this.state.searchResult.name}
                    </div>
                    <div class='col-10 FR W16'>
                        {this.state.searchResult.sub_group} 
                    </div>
                    <div class='col-2 LR'>
                        {this.state.searchResult.data_type}
                    </div>
                </div>
                <hr></hr>
            </>
        )  
    }
}
export default SearchResultsLayout;