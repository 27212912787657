import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBNavbarLink, MDBDropdownLink
} from 'mdb-react-ui-kit';
import { BrowserRouter as Router, Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'
import logo from '../assets/WBDLogo.png';
import { withOktaAuth } from '@okta/okta-react';
import { withUserContext } from '../../contexts/UserContext';
import { withNotificationContext } from '../../contexts/NotificationContext';
import HorizontalMenu from '../pages/NavigationMenu/navMenu';
import navMenu from '../pages/NavigationMenu/navMenu';
import UserManualAdmin from '../../caster-tv-jwp/assets/TV Caster__User Manual - Admin.pdf';
import UserManualShowUser from '../../caster-tv-jwp/assets/TV Caster__User Manual - Show User.pdf';

export default 
    withUserContext(
        withNotificationContext(
        withOktaAuth(class HeaderContent extends React.Component {
            constructor(props) {
                super(props);
                this.logout = this.logout.bind(this);
                this.login = this.login.bind(this);
                this.state = {
                    confirmSaveChanges: false,
                    switch_tenant_id: 0,
                    switch_tenant_name: "",
                    isManageUser: false
                }
            }

            async logout() {
                var len = localStorage.length;
                var keys = Object.keys(localStorage);
                for (var i = 0; i < len; i++) {
                    var key = keys[i];
                    if (key.indexOf("okta-") == -1)
                        localStorage.removeItem(key);
                }

                this.props.oktaAuth.signOut('/');
            }


            async login() {
                this.props.authService.login('/');
            }
            handleSideNavActive = (value, routePath) => {
                this.props.history.push(routePath, this.state);
            }

            gotoNavigation=(item) =>{
                console.log("test");
            }

            render() {
                const bgColorBlue = { backgroundColor: '#0062AF' }
                const adminTitle = { fontSize: '13px', color: '#ffffff', padding: '2px' }
                const ptli = { paddingTop: '0.1rem' }
                let userRole = this.props?.userContext?.user_profile?.user_role_name;

                return (
                    <div id="headerContent">
                        <header  >
                            <div className="fixed-top">
                                <MDBNavbar bgColor="primary" dark expand="lg" fixed sticky >
                                    <MDBContainer fluid>
                                        <MDBNavbarBrand className='mb-0 h1'>

                                            <Link component={RouterLink} className="action-icon" to={'/caster-tv-jwp'} >
                                                <img
                                                    src={logo}
                                                    alt='Logo' className="tvCastingImageWidth"
                                                />
                                                </Link>

                                            <h1 className="app-title">
                                                CASTER 
                                        </h1>
                                        </MDBNavbarBrand>
                                        <HorizontalMenu history={this.props?.history}/>
                                        <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>

                                            <MDBNavbarItem className="padding-hor-25" >
                                                <MDBDropdown >
                                                    <MDBDropdownToggle tag="a" className="bgColorBlue shadow-none text-white" >

                                                        <MDBIcon fas size='3x' icon="user-circle" className="mr-2" />
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu className="text-end border border-1-quinary">
                                                    <MDBDropdownItem active={false} key={"manual"}>
                                                        {userRole === 'Show User' ? 
                                                            <MDBDropdownLink className="text-tertiary logout-link" href={UserManualShowUser} download="UserManualShowUser.pdf">
                                                                <small>{'Show User Manual'}</small>
                                                            </MDBDropdownLink> :
                                                            <>
                                                                <MDBDropdownLink className="text-tertiary logout-link" href={UserManualAdmin} download="UserManualAdmin.pdf">
                                                                    <small>{'Admin User Manual'}</small>
                                                                </MDBDropdownLink>
                                                                <MDBDropdownLink className="text-tertiary logout-link" href={UserManualShowUser} download="UserManualShowUser.pdf">
                                                                    <small>{'Show User Manual'}</small>
                                                                </MDBDropdownLink>
                                                         </>
                                                        }
                                                            
                                                    </MDBDropdownItem>
                                                        <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}
                                                            disabled={!this.props.isLoggedIn} onClick={this.logout} >
                                                            <MDBDropdownLink className="fw-bold" active={false} to='/'>Logout</MDBDropdownLink >
                                                        </MDBDropdownItem>
                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </MDBNavbarItem>

                                        </MDBNavbarNav>
                                    </MDBContainer>
                                </MDBNavbar>                                
                            </div>
                        </header>
                    </div>
                );
            }
        }
        )
    ));