import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBNavbarLink, MDBDropdownLink
} from 'mdb-react-ui-kit';
import { BrowserRouter as Router, Link as RouterLink, NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'
import logo from '../assets/WB_logo.png';
import { withOktaAuth } from '@okta/okta-react';
import AsynchronousSearch from '../components/SharedComponents/AsynchronousSerach/AsynchronousSearch'
import { withUserContext } from '../../contexts/UserContext';
import { withNotificationContext } from '../../contexts/NotificationContext';
import MessageModal from '../components/SharedComponents/MessageModal';
import ModalComponent from '../Common/DefaultModalLayout/Modal/Modal';
import {reportsList} from '../components/Reports/Config';
import MainViewModalComponent from '../Common/MainViewModalLayout/Modal/Modal'
import '../components/SharedComponents/shared.scss'

export default 
    withUserContext(
        withNotificationContext(
        withOktaAuth(class HeaderContent extends React.Component {
            constructor(props) {
                super(props);
                this.logout = this.logout.bind(this);
                this.login = this.login.bind(this);
                this.state = {
                    confirmSaveChanges: false,
                    switch_tenant_id: 0,
                    switch_tenant_name: "",
                    isManageUser: false,
                    showReportPopup: false,
                    openLookupModal: false
                }
            }

            async logout() {
                var len = localStorage.length;
                var keys = Object.keys(localStorage);
                for (var i = 0; i < len; i++) {
                    var key = keys[i];
                    if (key.indexOf("okta-") == -1)
                        localStorage.removeItem(key);
                }

                this.props.oktaAuth.signOut('/');
            }


            async login() {
                // Redirect to '/' after logout
                this.props.authService.login('/');
            }
            handleSideNavActive = (value, routePath) => {
                this.props.history.push(routePath, this.state);
            }

            switchTenant = (tenant_id, tenant_name) => {
                this.setState({ switch_tenant_id: tenant_id, switch_tenant_name: tenant_name, confirmSaveChanges: !this.state.confirmSaveChanges });
            }

            confirmCancelChanges = (isCancel) => {
                //if (isCancel) {
                this.props.userContext.switchTenant(this.state.switch_tenant_id);
                //}
                //else {
                this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                //}
            }
            componentWillReceiveProps(nextProps) {
                this.setState({
                    isManageUser: nextProps.userContext?.active_tenant?.permissions?.isManageUser
                });
            }

            goToReportsModal=()=>{
                this.setState({showReportPopup: true})
            }

            gotoMainContent = () => {
                this.setState({ openLookupModal: true })
            }

            render() {
                const bgColorBlue = { backgroundColor: '#0062AF' }
                const adminTitle = { fontSize: '13px', color: '#ffffff', padding: '2px' }
                const ptli = { paddingTop: '0.1rem' }
                const navHeadings = { color: '#ffffff'}

                return (
                    <div id="headerContent" className='caster-feature'>
                        {/* <Router fixed="top"> */}
                        <header  >
                            <div className="fixed-top">
                                <MDBNavbar bgColor="primary" dark expand="md" fixed sticky >
                                    <MDBContainer fluid>
                                        <MDBNavbarBrand className='mb-0 h1 logo-title'>

                                            {/* <MDBNavbarLink tag={Link} active={false} className="nav-link-override" to="/" > */}
                                            <Link component={RouterLink} className="action-icon" to={'/caster-feature'} >
                                                <img
                                                    src={logo}
                                                    alt='Logo' className="imageWidth"
                                                //onClick={this.handleSideNavActive.bind(this, 'LP', "/")}
                                                />
                                                </Link>

                                            {/* </MDBNavbarLink> */}
                                            <h1 className="app-title">
                                                CASTER 
                                        </h1>
                                        </MDBNavbarBrand>
                                        <div className="d-flex">
                                            <div className="d-flex justify-content-end"><NavLink to="/caster-feature/adc" className="sub-headings" exact>Advanced Search</NavLink></div>
                                        </div>
                                        <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0 bgColorBlue' style={{ marginRight: "5px"}}>
                                            <MDBNavbarItem>
                                                <MDBDropdownLink style={{backgroundColor: '#164e9f', color: "white"}} onClick={() => this.goToReportsModal()}>
                                                   Reports
                                                </MDBDropdownLink>
                                            </MDBNavbarItem>
                                        </MDBNavbarNav>
                                        <MDBNavbarNav className='mb-2 mb-lg-0 width-10' style={{ marginRight: "20px"}}>
                                                    <MDBNavbarItem>
                                                        <MDBDropdown>
                                                            <MDBDropdownToggle tag='a' className='nav-link'>
                                                                Admin Functions
                                                            </MDBDropdownToggle>
                                                            <MDBDropdownMenu>
                                                                <MDBDropdownItem>
                                                                    <MDBDropdownLink className={"onHover"} onClick={() => this.gotoMainContent()}>
                                                                        Look Ups
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            </MDBDropdownMenu>
                                                        </MDBDropdown>
                                                    </MDBNavbarItem>
                                                </MDBNavbarNav>
                                        <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>
                                            <MDBNavbarItem className="padding-hor-25" >
                                                <MDBDropdown >
                                                    <MDBDropdownToggle tag="a" className="bgColorBlue shadow-none text-white" >
                                                        <MDBIcon fas size='3x' icon="user-circle" className="mr-2" />
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu className="text-end border border-1-quinary">
                                                        {this.props.userContext?.user_profile?.user_roles?.map((tenant) => {
                                                            if (tenant.tenant_id != this.props.userContext?.active_tenant?.tenant_id)
                                                                return <MDBDropdownItem data-active={false} key={tenant.tenant_id}
                                                                    onClick={this.switchTenant.bind(this, tenant.tenant_id, tenant.tenant_name)}>
                                                                    <MDBDropdownLink className="text-tertiary" active={false} to='/'>
                                                                        <small>{tenant.tenant_name}</small>
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            else
                                                                return <MDBDropdownItem data-active={false} key={tenant.tenant_id}                                                                >
                                                                    <MDBDropdownLink className="text-dark" active={false} >
                                                                        <small>{tenant.tenant_name}</small>
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                        }
                                                        )}
                                                        <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}
                                                            disabled={!this.props.isLoggedIn} onClick={this.logout} >
                                                            <MDBDropdownLink className="fw-bold" active={false} to='/'>Logout</MDBDropdownLink >
                                                        </MDBDropdownItem>
                                                        <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}>
                                                            <MDBDropdownLink className="fw-bold" active={false} to='/'>{this.props?.user_name} - {this.props?.user_profile?.is_user_provisioner === 1 ? 'User Provisioner' : this.props?.user_role_active_tenant}</MDBDropdownLink >
                                                        </MDBDropdownItem>
                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </MDBNavbarItem>

                                        </MDBNavbarNav>
                                    </MDBContainer>
                                </MDBNavbar>
                                <MessageModal
                                    open={this.state.confirmSaveChanges}
                                    title={"Unsaved Changes"}
                                    message={"Switching tenant will lose any unsaved changes. Do you want to proceed?"}
                                    primaryButtonText={"Yes"}
                                    secondaryButtonText={"No"}
                                    onConfirm={() => this.confirmCancelChanges()}
                                    handleClose={(e) => this.setState({ confirmSaveChanges: false })} />
                                {this.state.showReportPopup &&
                                    <ModalComponent
                                        // navFuns={this.functions}
                                        open={this.state.showReportPopup}
                                        handleClose={()=>this.setState({showReportPopup: false})}
                                        tabList={reportsList}
                                        headerText={"Reports"}
                                        objectType={'REPORTS'}
                                    />
                                }
                            </div>
                        </header>
                        {this.state.openLookupModal && (
                                    <MainViewModalComponent
                                        open={this.state.openLookupModal}
                                        handleClose={() => this.setState({ openLookupModal: false })}
                                        headerText={"Lookup Values"}
                                        mode={""}
                                        modalName={"Lookup Values"}
                                    />
                                )}
                    </div>
                );
            }
        }
        )
    ));