
export const navTopMenus = [
    {
        label: "Reports",
        type: "navLink",
    },
    {
        label: "Talent",
        type: "navLink",
    },
    {
        label: "Representatives",
        type: "navLink",
    },
    {
        label: "Companies",
        type: "navLink",
    },
    {
        label: "Admin Functions",
        type: "navDropdown",
        menuItems: [
            {
                id: 1,
                label: "Lookup Values",
                sectionName: "lookupValues",
            },
        ]
    },
]