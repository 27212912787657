import React from 'react';
import HeaderContentFeature from '../caster-feature/header/HeaderContent';
import HeaderContentTV from '../caster-tv-jwp/header/HeaderContent';
import HeaderContentAnimation from '../caster-animation/header/HeaderContent';
import { MDBContainer } from 'mdbreact';

function ErrorPage(props){
    return( 
        <>      
         <MDBContainer >
            <HeaderContentAnimation isLoggedIn = {props.isLoggedIn}/> 
            <HeaderContentFeature isLoggedIn = {props.isLoggedIn}/> 
            <HeaderContentTV isLoggedIn = {props.isLoggedIn}/> 
            <div style="margin-top: 65px">
                <h1>Error Id : {props.errorId}</h1>
                <p>Error Description: {props.errorDesc}</p>
            </div>
        </MDBContainer>
        
        </>
    )}

export default ErrorPage;