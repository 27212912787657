import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import './navMenu.scss';
import '../../../../src/index.scss';
import { MDBNavbarNav, MDBNavbarLink, MDBNavbarItem }
    from "mdb-react-ui-kit";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBDropdownLink } from 'mdb-react-ui-kit';
import { withUserContext } from '../../../contexts/UserContext';
import { navTopMenus, navTopMenuShowUser } from './config';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';

let commonPermissions = [{ permissionGroupName: "Admin Functions", permission_name: "Project Assignment" }, { permissionGroupName: "Admin Functions", permission_name: "Lookup Values" },{ permissionGroupName: "Admin Functions", permission_name: "Onboarding Forms" }], selectedItems = new Set();
export default withOktaAuth(withUserContext(class navMenu extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isAdminFunctions: false,
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.fetchPermissions();
        this.menuPermissionExists();
    }

    componentWillMount() {
        this._isMounted = false;
    }
    componentWillReceiveProps(nextProps) {
        this.fetchPermissions();
        this.menuPermissionExists();
    }

    gotoMainContent = (selection) => {
        if (selection.label === 'Project Assignment') {
            this.props.history.push(`/caster-tv-jwp/project-assignment`)
            window.location.reload();
        } else if (selection.label === 'Search Talent') {
            this.props.history.push(`/caster-tv-jwp/search-talent`)
            window.location.reload();
        } else if (selection?.label === 'Lookup Values') {
            this.setState({ openLookupModal: true })
        }
    }

    fetchPermissions = () => {
        if (this.props?.userContext?.permissions?.length > 0) {
            commonPermissions = this.props?.userContext?.permissions;
        }
    }

    renderDropDownItems = (itemSelected) => {
        selectedItems = new Set();
        itemSelected.menuItems.map((mItem) => {
            commonPermissions.map((lItem) => {
                if ((mItem.permissionNames.includes(lItem.permission_name))) {
                    selectedItems.add(mItem)
                }
            })
        })
        return selectedItems.size > 0 ? true : false;
    }

    menuPermissionExists = () => {
        navTopMenus.map((item) => {
            commonPermissions?.map((per) => {
                item.menuItems?.some((itemPerm) => {
                    if ((itemPerm.permissionNames?.includes(per.permission_name) && per.view === 1)) {
                        (item.permissionGroupName === "Admin Functions") ?
                            this.setState({ isAdminFunctions: true }) : null;
                        return true;
                    } else {
                        return false;
                    }
                })
            })
        })
    }

    render() {
        return (
            <div className='ms-auto'>
                {(this.props?.history?.location?.pathname?.toLowerCase()?.indexOf(('userRoleManagement')?.toLowerCase()) !== -1) ?
                    <div className="urm-nav-text">
                        You are logged into User Role Management
                    </div> :
                    <MDBNavbarNav className='mb-2 mb-lg-0'>
                        {
                            this.props?.userContext.user_profile.user_role_name === "Show User" ? (<>{navTopMenuShowUser.map((item) =>
                                <MDBNavbarItem >
                                    {/* {(item.type === "navDropdown" && !this.state.isAdminFunctions) ?
                                        <MDBDropdown>
                                            <MDBDropdownToggle tag='a' className='nav-link' >
                                                {item.label}
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu>
                                                {this.renderDropDownItems(item) ?
                                                    Array.from(selectedItems).map((value) =>
                                                        <MDBDropdownItem>
                                                            <MDBDropdownLink className={"onHover"} onClick={() => this.gotoMainContent(value)}>
                                                                {value.label}
                                                            </MDBDropdownLink>
                                                        </MDBDropdownItem>
                                                    )
                                                    : null
                                                }
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                        : <MDBNavbarLink className={"onHover"} onClick={() => this.gotoMainContent(item)}>{item.label}</MDBNavbarLink>
                                    } */}
                                </MDBNavbarItem>

                            )}</>) : (<>
                                {navTopMenus.map((item) =>
                                    <MDBNavbarItem >
                                        {(item.type === "navDropdown" && !this.state.isAdminFunctions) ?
                                            <MDBDropdown>
                                                <MDBDropdownToggle tag='a' className='nav-link' >
                                                    {item.label}
                                                </MDBDropdownToggle>
                                                <MDBDropdownMenu>
                                                    {this.renderDropDownItems(item) ?
                                                        Array.from(selectedItems).map((value) =>
                                                            <MDBDropdownItem>
                                                                <MDBDropdownLink className={"onHover"} onClick={() => this.gotoMainContent(value)}>
                                                                    {value.label}
                                                                </MDBDropdownLink>
                                                            </MDBDropdownItem>
                                                        )
                                                        : null
                                                    }
                                                </MDBDropdownMenu>
                                            </MDBDropdown>
                                            : <MDBNavbarLink className={"onHover"} onClick={() => this.gotoMainContent(item)}>{item.label}</MDBNavbarLink>
                                        }
                                    </MDBNavbarItem>

                                )}</>)

                        }

                    </MDBNavbarNav>
                }
                {this.state.openLookupModal && (
                    <MainViewModalComponent
                        open={this.state.openLookupModal}
                        handleClose={() => this.setState({ openLookupModal: false })}
                        headerText={"Lookup Values"}
                        mode={""}
                        modalName={"Lookup Values"}
                    />
                )}
            </div>
        );
    }
}
))