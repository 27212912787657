
export const navTopMenus = [
    {
        label: "Search Talent",
        type: "navLink",
        permissionNames: ["Search Talent"]
    },
    {
        label: "Admin Functions",
        type: "navDropdown",
        permissionNames: ["Project Assignment", "Lookup Values", "Onboarding Forms"],
        menuItems: [
            {
                id: 1,
                label: "Project Assignment",
                sectionName: "projectAssignment",
                permissionNames: ["Project Assignment"]
            },
            {
                id: 2,
                label: "Lookup Values",
                sectionName: "lookupValues",
                permissionNames: ["Lookup Values"]
            },
            // {
            //     id: 3,
            //     label: "Onboarding Forms",
            //     icon:"link",
            //     sectionName: "onboardingForms",
            //     permissionNames: ["Onboarding Forms"]
            // },
        ]
    },
]

export const navTopMenuShowUser = [
    {
        label: "Search Talent",
        type: "navLink",
        permissionNames: ["Search Talent"]
    }
]