import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import './NavMenu.scss';
import '../../../../src/index.scss';
import { MDBNavbarNav, MDBNavbarLink, MDBNavbarItem } from "mdb-react-ui-kit";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBDropdownLink } from 'mdb-react-ui-kit';
import { withUserContext } from '../../../contexts/UserContext';
import { navTopMenus } from './Config';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import { talentAnimationList, representativeAnimationList, companyAnimationList, reportAnimationList } from '../../../constants/NavList';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';

let selectedItems = new Set();
export default withOktaAuth(withUserContext(class NavMenu extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isAdminFunctions: false,
        }
    }
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillMount() {
        this._isMounted = false;
    }

    handleOpenTalentPopup = () => {
        this.setState({ showTalentPopup: true })
    }

    handleOpenRepresentativePopup = () => {
        this.setState({ showRepresentativePopup: true })
    }

    handleOpenCompanyPopup = () => {
        this.setState({ showCompanyPopup: true })
    }

    handleOpenReportPopup = () => {
        this.setState({ showReportPopup: true })
    }

    functions = {
        handleHideTalentPopup: () => {
            this.setState({ showTalentPopup: false });
        },
        handleHideRepresentativePopup: () => {
            this.setState({ showRepresentativePopup: false });
        },
        handleHideCompanyPopup: () => {
            this.setState({ showCompanyPopup: false });
        },
        handleHideReportPopup: () => {
            this.setState({ showReportPopup: false });
        }
    }

    gotoMainContent = (selection) => {
        if (selection.label === 'Talent') {
            this.props?.history?.push(`/caster-animation/search-talent`)
            window.location.reload();
        } else if (selection.label === 'Representatives') {
            this.props?.history?.push(`/caster-animation/search-representatives`)
            window.location.reload();
        } else if (selection?.label === 'Companies') {
            this.props?.history?.push(`/caster-animation/search-companies`)
            window.location.reload();
        } else if (selection?.label === 'Reports') {
            this.handleOpenReportPopup();
        } else if (selection?.label === 'Lookup Values') {
            this.setState({ openLookupModal: true })
        }
    }

    renderDropDownItems = (itemSelected) => {
        selectedItems = new Set();
        itemSelected.menuItems.map((mItem) => {
            selectedItems.add(mItem)
        })
        return selectedItems.size > 0 ? true : false;
    }

    render() {
        return (
            <div className='ms-auto'>
                {this.props?.history?.location?.pathname?.toLowerCase() === '/caster-animation/user-role-management' ?
                    <div className="urm-nav-text">
                        You are logged into User Role Management
                    </div> :
                    <MDBNavbarNav className='mb-2 mb-lg-0'>
                        {navTopMenus.map((item) =>
                            <MDBNavbarItem >
                                {(item.type === "navDropdown" && !this.state.isAdminFunctions) ?
                                    <>
                                        {this.props?.userContext?.user_profile?.role_name === 'Admin' &&
                                            <MDBDropdown>
                                                <MDBDropdownToggle tag='a' className='nav-link' >
                                                    {item.label}
                                                </MDBDropdownToggle>
                                                <MDBDropdownMenu>
                                                    {this.renderDropDownItems(item) ?
                                                        Array.from(selectedItems).map((value) =>
                                                            <MDBDropdownItem>
                                                                <MDBDropdownLink className={"onHover"} onClick={() => this.gotoMainContent(value)}>
                                                                    {value.label}
                                                                </MDBDropdownLink>
                                                            </MDBDropdownItem>
                                                        )
                                                        : null
                                                    }
                                                </MDBDropdownMenu>
                                            </MDBDropdown>
                                        }

                                    </>
                                    : <MDBNavbarLink className={"onHover"} onClick={() => this.gotoMainContent(item)}>{item.label}</MDBNavbarLink>
                                }
                            </MDBNavbarItem>

                        )}
                    </MDBNavbarNav>
                }
                {this.state.showTalentPopup &&
                    <ModalComponent
                        navFuns={this.functions}
                        open={this.state.showTalentPopup}
                        handleClose={this.functions.handleHideTalentPopup}
                        tabList={talentAnimationList}
                        bodyText={"Search for Talent to view their Data, or create a new Talent"}
                        searchPlaceholder={"Search Talent by name or SSN"}
                        headerText={"Talent"}
                        addButtonText={"New Talent"}
                        objectType={'TALENT'}
                        dataUrl='talentDetails'
                    />
                }
                {this.state.showRepresentativePopup &&
                    <ModalComponent
                        navFuns={this.functions}
                        open={this.state.showRepresentativePopup}
                        handleClose={this.functions.handleHideRepresentativePopup}
                        tabList={representativeAnimationList}
                        headerText={"Representatives"}
                        objectType={'REPRESENTATIVE'}
                    />
                }
                {this.state.showCompanyPopup &&
                    <ModalComponent
                        navFuns={this.functions}
                        open={this.state.showCompanyPopup}
                        handleClose={this.functions.handleHideCompanyPopup}
                        tabList={companyAnimationList}
                        headerText={"Company"}
                        objectType={'COMPANY'}
                    />
                }
                {this.state.showReportPopup &&
                    <ModalComponent
                        navFuns={this.functions}
                        open={this.state.showReportPopup}
                        handleClose={this.functions.handleHideReportPopup}
                        tabList={reportAnimationList}
                        headerText={"Reports"}
                        objectType={'REPORT'}
                    />
                }
                {this.state.openLookupModal && (
                    <MainViewModalComponent
                        open={this.state.openLookupModal}
                        handleClose={() => this.setState({ openLookupModal: false })}
                        headerText={"Lookup Values"}
                        mode={""}
                        modalName={"Lookup Values"}
                    />
                )}
            </div>
        );
    }
}
))