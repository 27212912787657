import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import { MDBSpinner } from 'mdb-react-ui-kit';
import MainContent from '../main-content/MainContent';
import HeaderContent from '../header/HeaderContent';
import {downloadUrl} from '../Common/Helper'
import NotificationComponent from '../Common/NotificationComponent/NotificationComponent';
import ErrorPage from '../../error-boundary/ErrorPage';
import { withOktaAuth } from '@okta/okta-react';
import CasterService from '../../services/service';
import * as Constants from '../../constants/constants';
import {
  BrowserRouter as Router, withRouter
} from "react-router-dom";
import IdleTimer from 'react-idle-timer'
import { UserContext } from '../../contexts/UserContext';
import { NotificationContext } from '../../contexts/NotificationContext'
import { FilterContext } from '../../contexts/FilterContext'
export default withRouter(
  withOktaAuth(class Home extends Component {

    constructor(props) {
      super(props);
      if (this.props.authState.accessToken == undefined)
        this.props.oktaAuth.signOut('/');
      this.idleTimer = null
      this.onIdle = this._onIdle.bind(this);
      this.state = {
        isValidUser: true,
        isLoading: true,
        isLoggedIn: false,
        user_name: null,
        user_full_name: null,
        user_profile: null,
        user_profile_okta: null,
        downloadCount: 0,
        user_role_active_tenant:null,
        showNotification : false,
        notificationMessage:"",
        notificationSeverity: 'success',
        errorId: 0,
        errorDesc: '',
        filterData:[],
        filterNavigationName:'',
        inputValues:[],
        active_tenant: {
          tenant_id: null,
          tenant_name: null,
          role: null,
          permissions: {
            isAdmin: false,
            isManager: false,
            isRegular: false,
            isReadOnly: false,
            isManageUser: false
          }
        },
        tenants: [],
        active_route: '/'
      }

      this.mainContent = React.createRef();
    }

    _onIdle(e) {

      //authService.logout();
      this.props.oktaAuth.signOut('/');
      this.setState({
        isLoggedOut: true,
        errorId: '440',
        errorDesc: 'Session is timed out. Please login again!'
      });

    }
    handleSideNav = (value, routePath) => {
      this.setState({
        //isOpen: !this.state.isOpen
        activeRoute: routePath
      });
    }

    async componentDidMount() {
      try {
        this._isMounted = true;
        await this.props.oktaAuth.getUser()
          .then(response => {
            let name = response.name
            let email = response.email;

            //console.log("User Info :" + JSON.stringify(response));
            localStorage.setItem('user_email', email);
            //localStorage.setItem('user_name', name);
            localStorage.setItem('user_full_name', name);
            //localStorage.setItem('user_profile_okta', JSON.stringify(UserProfile));
            this.setState({ user_full_name: name }); //isLoading : false,

            CasterService.getData(Constants.CasterServiceBaseUrl + '/casterFeatureLoggedInUser', 0)//"./user_data.json") //Constants.CasterSharedServiceBaseUrl + 'User?userEmail=' + email, this.props.userContext?.active_tenant?.tenant_id)
              .then(response => {
                if (this._isMounted) {
                  //console.log("User Profile -->" + JSON.stringify(response));
                  if (response) { // (response.data && response.data.length > 0) {
                    let userProfile = response.data[0];// UserProfile; // response.json(); //data[0];
                    //if(userprofile )

                    let userId = userProfile.user_id;
                    localStorage.setItem('user_id', userId);
                    localStorage.setItem('user_profile', JSON.stringify(userProfile));

                    var is_user_provisioner = userProfile.is_user_provisioner;
                    var is_active_user = userProfile.is_active;

                    if (!is_active_user) {
                      this.setState({
                        isValidUser: false,
                        isLoggedIn: false,
                        isLoading: false,
                        errorId: '401',
                        errorDesc: 'User is marked as inactive in the system. Please contact admin for Caster application'
                      });
                    }
                    else {
                      if (is_user_provisioner) {
                        userProfile.default_tenant_id = 0;
                      }
                      this.setState({
                        isValidUser: true,
                        isLoading: false,
                        isLoggedIn: true,
                        user_name: userProfile.name,
                        user_profile: userProfile
                      });
                    }
                    this.processPermissions(userProfile, is_user_provisioner);
                  }
                  else {
                    this.setState({
                      isValidUser: false,
                      isLoggedIn: false,
                      isLoading: false,
                      errorId: '401',
                      errorDesc: 'User is not available in the system or marked as inactive. Please contact admin for Caster application'
                    });
                  }
                }
              },
                (err) => {
                  console.log('Service Error : Log In -> Getting User Profile ' + err);
                  this.setState({
                    isValidUser: false,
                    isLoggedIn: false,
                    isLoading: false,
                    errorId: '503',
                    errorDesc: 'User profile service is not available. Please try again and contact admin for Caster application'
                  });
                });


          },
            (err) => {
              console.log(err);
              this.setState({
                isValidUser: false,
                isLoggedIn: false,
                isLoading: false,
                errorId: '503',
                errorDesc: 'OKTA user profile service is not available. Please try again and contact admin for Caster application'
              });
            });
      }
      catch (err) { }
    }
    processPermissions(userProfile, is_user_provisioner) {

      //let permissions = appConstants.PERMISSIONS;
      //let permissionObjs = [];
      if (userProfile) {
        // debugger;
        let tenants = [];
        var default_tenant_id = 1; // userProfile.default_tenant_id;
        var active_tenant = localStorage.getItem("active_tenant_id");
        var active_tenant_name = localStorage.getItem("active_tenant_name");
        var active_tenant_role;
        // this.setState({ tenants: userProfile.last_logged_at });
        // userProfile.last_logged_at.forEach((tenant) => {
        this.setState({ tenants: userProfile.user_roles });
        if ((active_tenant == "null" || !active_tenant) ) {  
          localStorage.setItem("active_tenant_id", userProfile.tenant_id);
          localStorage.setItem("active_tenant_name", userProfile.tenant_name);
          active_tenant = userProfile.tenant_id;
        }
        userProfile.user_roles?.forEach((tenant) => {
          if (tenant.tenant_id == null) {
            tenant.tenant_id = 0;
            tenant.tenant_name = "All Tenants";
            tenant.role_name = '';
          }
          tenants.push({
            "tenant_id": tenant.tenant_id,
            "tenant_name": tenant.tenant_name,
            "role_name": tenant.user_role_name
          });
          this.setState({role: is_user_provisioner ? 'User Provisioner' : tenant?.user_role_name})
          localStorage.setItem("tenants", tenants);

          if ((active_tenant == "null" || !active_tenant) ) {  //&& default_tenant_id == tenant.tenant_id
            localStorage.setItem("active_tenant_id", userProfile.tenant_id);
            localStorage.setItem("active_tenant_name", userProfile.tenant_name);
            active_tenant = tenant.tenant_id;
          }
          if (active_tenant == tenant.tenant_id || userProfile.is_user_provisioner === 1) {
            this.processPermissionsforTenant(tenant, is_user_provisioner);
          }
          //permissionObjs.push(permObj);
        });
        //localStorage.setItem("permissions", permissionObjs);     

      }

    }


    processPermissionsforTenant(tenant, is_user_provisioner) {
      var isManageUser = false;
      var active_tenant_id = tenant.tenant_id;
      var active_tenant_name = tenant.tenant_name;
      var active_tenant_role = tenant.role;


      var active_tenant = { ...this.state.active_tenant };
      var permissions_tenant = active_tenant.permissions;

      var isAdmin = tenant?.user_role_name && tenant?.user_role_name?.toLowerCase() == 'admin' ? true : false;
      var isManager = tenant?.user_role_name && tenant?.user_role_name?.toLowerCase() == 'business manager' ? true : false;
      var isRegular = tenant?.user_role_name && tenant?.user_role_name?.toLowerCase() == 'business user' ? true : false;
      var isReadOnly = tenant?.user_role_name && tenant?.user_role_name?.toLowerCase() == 'read-only user' ? true : false;
      this.setState({user_role_active_tenant:tenant?.user_role_name})

      tenant.permissions.map((permission) => {
        permissions_tenant.isAdmin = isAdmin;
        permissions_tenant.isManager = isManager;
        permissions_tenant.isRegular = isRegular;
        permissions_tenant.isReadOnly = isReadOnly;
        if (is_user_provisioner === 1) {
          permissions_tenant.isManageUser = true;
        }
        if (permission.permission_name === "MANAGE USERS") {
          permissions_tenant.isManageUser = true;
        }
        if (is_user_provisioner) {
          active_tenant_role = "User Provisioner";
        }

      });

      active_tenant.tenant_id = active_tenant_id;
      active_tenant.tenant_name = active_tenant_name;
      active_tenant.role = active_tenant_role;
      active_tenant.permissions = permissions_tenant;

      console.log("User Profile - Active Tenant:" + JSON.stringify(active_tenant));

      this.setState({
        active_tenant: active_tenant
      });

      localStorage.setItem("active_tenant_id", tenant.tenant_id);
      localStorage.setItem("active_tenant_name", tenant.tenant_name);
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    switchTenant = (tenant_id) => {
      //console.log("Switched to Tenant -->"+tenant_id);
      var tenant = this.state.user_profile.user_roles.find(tenant => tenant.tenant_id == tenant_id);
      this.processPermissionsforTenant(tenant, this.state.user_profile.is_user_provisioner);
      this.props.history.push('/', this.state);
      window.location.reload();
    }
    filterTableData=(dashboardValues)=>{
      console.log(dashboardValues?.page);
      console.log(dashboardValues?.rowsPerPage);
      console.log(dashboardValues?.navigation);
      console.log(dashboardValues?.filterValues);
      console.log(dashboardValues?.inputValues);
      this.setState({
        filterNavigationName:  dashboardValues?.navigation!=null ? dashboardValues?.navigation :this.state?.filterNavigationName,
        filterData: dashboardValues?.filterValues !=null ? dashboardValues?.filterValues:this.state?.filterData ,
        inputValues: dashboardValues?.inputValues!=null? dashboardValues?.inputValues :this.state?.inputValues,
        page: dashboardValues?.page !=null ? dashboardValues?.page:this.state?.page,
        rowsPerPage: dashboardValues?.rowsPerPage !=null ? dashboardValues?.rowsPerPage:this.state?.rowsPerPage
      })
    }

    updateDownLoadCount = (value) => {
      this.setState({downloadCount : value })
    }

    notificationBar  = (value, message, severity) => {
      this.setState({showNotification : value, notificationMessage: message, notificationSeverity : severity })
    }

    webSocketReportDownload = (webSocketConn, reportNameFinal) => {
      webSocketConn.onmessage = (e) => {
          console.log('checkthereposne', e)
          let response = JSON.parse(e?.data)
          if(response?.statusCode == 200) {
            if(response?.body == 'No Data Found' || response?.body == 'Template Data not found' || response?.body == 'Report template not found'  ) {
              this.setState({showNotification: false}, () => {
                        this.notificationBar(true,response?.body, 'error')
                    });
            } else {
              this.notificationBar(false,'', 'success')
              downloadUrl(response?.body, reportNameFinal);
            }
            this.updateDownLoadCount(this.state.downloadCount - 1)
          }
      }
    }

    render() {
      var currentTenant = this.state?.active_tenant?.tenant_name || 'Feature Casting';
      
      return (
        (this.state.isLoading ?

          <div className='alignCenter '>
            <div className="align-center-vertical">
              <MDBSpinner role='status' color="primary">
              </MDBSpinner>
              <div>
                <span style={{ textAlign: 'center' }}>Loading Feature Caster....</span>
              </div>
            </div>
          </div>

          : (
            !this.state.isValidUser ?
              <ErrorPage isLoggedIn={this.state.isValidUser} errorId={this.state.errorId}
                errorDesc={this.state.errorDesc} ></ErrorPage>
              :

              <>
                <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onIdle={this.onIdle}
                  debounce={250}
                  timeout={1000 * 60 * Constants.IDLE_TIMEOUT} />
                <UserContext.Provider value={{
                  user_profile: this.state.user_profile,
                  active_tenant: this.state.active_tenant,
                  switchTenant: this.switchTenant.bind(this),
                  filterTableData: this.filterTableData.bind(this),
                }}>
                  <NotificationContext.Provider value={{
                    downloadCount : this.state.downloadCount,
                    updateDownLoadCount: this.updateDownLoadCount.bind(this),
                    webSocketReportDownload:this.webSocketReportDownload.bind(this),
                    notificationBar:this.notificationBar.bind(this)
                  }}>
                    <FilterContext.Provider value={{
                    filterNavigationName : this.state.filterNavigationName,
                    filterData : this.state.filterData,
                    inputValues:this.state.inputValues,
                    page:this.state.page,
                    rowsPerPage:this.state.rowsPerPage
                  }}>
                  <Router>
                    <div className="h-100">
                      <HeaderContent {...this.state}
                        authState={this.props.authState} switchTenant={this.switchTenant.bind(this)} />

                    {(currentTenant === "TV Casting" || currentTenant === "JWP Casting") && (
                      <>
                      <MainContentTVCasting {...this.state} {...this.props}
                        {...this.functions}
                        wrappedComponentRef={this.mainContent}
                        switchTenant={this.switchTenant.bind(this)} />
                      </>
                    )}
                    {(currentTenant === "Feature Casting" || currentTenant === "TV Animation Casting") && (
                      <>
                      <MainContent {...this.state} {...this.props}
                        {...this.functions}
                        wrappedComponentRef={this.mainContent}
                        switchTenant={this.switchTenant.bind(this)} />
                      </>
                    )}
                    </div>
                    {<NotificationComponent open={this.state.showNotification} message={this.state.notificationMessage} severity={this.state.notificationSeverity}
                            handleResetNotify={() => {this.notificationBar(false,"", 'success')}} />
                    }
                  </Router>
                  </FilterContext.Provider>
                  </NotificationContext.Provider>
                </UserContext.Provider>
              </>
          )
        )
      );
    }
  }
  )
);