import React, { Component } from 'react';
import { MDBSpinner } from 'mdb-react-ui-kit';
import MainContentTVCasting from '../main-content/MainContent';
import TVHeaderContent from '../header/HeaderContent';
import {downloadUrl} from '../../caster-feature/Common/Helper'
import NotificationComponent from '../../caster-feature/Common/NotificationComponent/NotificationComponent';
import ErrorPage from '../../error-boundary/ErrorPage';
import { withOktaAuth } from '@okta/okta-react';
import CasterService from '../../services/service';
import * as Constants from '../../constants/constants';
import {
  BrowserRouter as Router, withRouter
} from "react-router-dom";
import IdleTimer from 'react-idle-timer'
import { UserContext } from '../../contexts/UserContext';
import { NotificationContext } from '../../contexts/NotificationContext'
export default withRouter(
  withOktaAuth(class Home extends Component {

    constructor(props) {
      super(props);
      if (this.props.authState.accessToken == undefined)
        this.props.oktaAuth.signOut('/');
      this.idleTimer = null
      this.onIdle = this._onIdle.bind(this);
      this.state = {
        isValidUser: true,
        isLoading: true,
        isLoggedIn: false,
        user_name: null,
        user_full_name: null,
        user_profile: null,
        user_profile_okta: null,
        downloadCount: 0,
        user_role_active_tenant:null,
        showNotification : false,
        notificationMessage:"",
        notificationSeverity: 'success',
        errorId: 0,
        errorDesc: '',
      }
      this.mainContent = React.createRef();
    }

    _onIdle(e) {
      this.props.oktaAuth.signOut('/');
      this.setState({
        isLoggedOut: true,
        errorId: '440',
        errorDesc: 'Session is timed out. Please login again!'
      });

    }

    async componentDidMount() {
      try {
        this._isMounted = true;
        await this.props.oktaAuth.getUser()
          .then(response => {
            localStorage.setItem('user_full_name', response.name);
            this.setState({ user_full_name: response.name });

            CasterService.getData(Constants.CasterServiceBaseUrl + '/casterTvJwpLoggedInUser', 0)
              .then(response => {
                if (this._isMounted) {
                  if (response?.data && response?.data.length > 0) {
                    let userProfile = response.data[0];
                    let userId = userProfile.user_id;
                    localStorage.setItem('user_id', userId);
                    localStorage.setItem('user_profile', JSON.stringify(userProfile));
                    
                      this.setState({
                        isValidUser: true,
                        isLoading: false,
                        isLoggedIn: true,
                        user_profile: userProfile
                      });
                    //this.processPermissions(userProfile);
                  }
                  else {
                    this.setState({
                      isValidUser: false,
                      isLoggedIn: false,
                      isLoading: false,
                      errorId: '401',
                      errorDesc: 'User is not available in the system or marked as inactive. Please contact admin for Caster application'
                    });
                  }
                }
              },
                (err) => {
                  console.log('Service Error : Log In -> Getting User Profile ' + err);
                  this.setState({
                    isValidUser: false,
                    isLoggedIn: false,
                    isLoading: false,
                    errorId: '503',
                    errorDesc: 'User profile service is not available. Please try again and contact admin for Caster application'
                  });
                });


          },
            (err) => {
              console.log(err);
              this.setState({
                isValidUser: false,
                isLoggedIn: false,
                isLoading: false,
                errorId: '503',
                errorDesc: 'OKTA user profile service is not available. Please try again and contact admin for Caster application'
              });
            });
      }
      catch (err) { }
    }
    // processPermissions(userProfile) {
    //   if (userProfile) {
    //     if(userProfile.user_role_name === "Admin"){
    //       this.setState({isAdminUser : true});  
    //     }else if(userProfile.user_role_name === "User Provisioner"){
    //       this.setState({isUserProvisioner : true})
    //     }else{
    //       this.setState({isShowUser: true})
    //     }
    //   }
    // }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      return (
        (this.state.isLoading ?
          <div className='alignCenter '>
            <div className="align-center-vertical">
              <MDBSpinner role='status' color="primary">
              </MDBSpinner>
              <div>
                <span style={{ textAlign: 'center' }}>Loading TV Caster....</span>
              </div>
            </div>
          </div>

          : (
            !this.state.isValidUser ?
              <ErrorPage isLoggedIn={this.state.isValidUser} errorId={this.state.errorId}
                errorDesc={this.state.errorDesc} ></ErrorPage>
              :

              <>
                <UserContext.Provider value={{
                  user_profile: this.state.user_profile,
                }}>
                  <Router>
                    <div className="h-100 caster-tv-jwp">
                      <TVHeaderContent {...this.state}
                        authState={this.props.authState} history={this.props?.history}/>
                       <>
                      <MainContentTVCasting {...this.state} {...this.props}
                        {...this.functions}
                        wrappedComponentRef={this.mainContent}
                        // switchTenant={this.switchTenant.bind(this)} 
                        />
                      </>
                    </div>
                    {<NotificationComponent open={this.state.showNotification} message={this.state.notificationMessage} severity={this.state.notificationSeverity}
                            handleResetNotify={() => {this.notificationBar(false,"", 'success')}} />
                    }
                  </Router>
                </UserContext.Provider>
              </>
          )
        )
      );
    }
  }
  )
);